import {behaviours} from '../../../global/scripts/behaviours/behaviour'

import {PaginationOptions, SwiperModule} from 'swiper/types'

const blockProjectSliderBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.baseSliderBlock')

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default
        const NavigationModule = module.Navigation as SwiperModule
        const EffectModule = module.EffectCreative as SwiperModule
        const PaginationModule = module.Pagination as SwiperModule

        const baseClass = 'baseSliderBlock'
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const slidesPerViewDesktop = block.hasAttribute('data-slides-per-view') ? parseInt(block.getAttribute('data-slides-per-view') as string) : 'auto'
        const sliderType = block?.getAttribute('data-slider-type')
        let renderBullet: PaginationOptions['renderBullet'] = undefined
        const slidesPerViewMobile = 1
        let slidesPerViewTablet = 2
        let spaceBetweenDesktop = 32
        let spaceBetweenTablet = 25

        let simulateTouch = true

        const navClass = 'slideNavigation__item'
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--previous`)

        if (!sliderElement) {
            return false
        }

        if (sliderType === 'steps') {
            renderBullet = function (index: number, className: string) {
                return '<span class="' + className + '">' + (index + 1) + '</span>'
            }

            slidesPerViewTablet = 3
        }

        if (sliderType == 'prices') {
            spaceBetweenDesktop = 0
            spaceBetweenTablet = spaceBetweenDesktop
            simulateTouch = false
        }

        new Swiper(sliderElement, {
            modules: [NavigationModule, EffectModule, PaginationModule],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: slidesPerViewMobile,
            spaceBetween: 20,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            threshold: 5,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: renderBullet,
            },
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    spaceBetween: spaceBetweenTablet,
                    slidesPerView: slidesPerViewTablet,
                },
                1024: {
                    spaceBetween: spaceBetweenDesktop,
                    slidesPerView: slidesPerViewDesktop,
                    simulateTouch: simulateTouch,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockProjectSliderBehaviour)
