import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import {Sticky} from 'sticky-ts'

const Header = (function () {
    const attach = function (context: HTMLElement) {
        const header = context.querySelector<HTMLElement>('.header')

        if (!header) {
            return
        }

        new Sticky('.header__menuButton')
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(Header)
