import {enableBodyScroll, disableBodyScroll} from 'body-scroll-lock'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const ModalBehaviour = (function () {
    const attach = function () {
        const documentClasslist = document.documentElement.classList
        const cssClass = 'modalsInitiated'
        if (documentClasslist.contains(cssClass)) {
            return
        }
        documentClasslist.add(cssClass)
        init(document)
    }

    const init = function (element) {
        const openModalLinks = element.querySelectorAll('.behaviourOpenModal')
        const closeModalLinks = element.querySelectorAll('.behaviourCloseModal')

        openModalLinks.forEach((link) => {
            if (link.hasAttribute('data-modal-selector')) {
                if (!document.querySelector(link.getAttribute('data-modal-selector'))) {
                    link.style.display = 'none'
                }
            }

            link.addEventListener('click', (event) => {
                event.preventDefault()
                if (link.hasAttribute('data-modal-selector')) {
                    openModal(link.getAttribute('data-modal-selector'))
                }
            })
        })

        closeModalLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault()
                const modal = link.closest('.modal')
                modal.classList.remove('modal--active')
                enableBodyScroll(modal)
            })
        })

        // Close modal on esc key
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                const modal = document.querySelector('.modal--active')
                if (modal) {
                    modal.classList.remove('modal--active')
                    enableBodyScroll(modal)
                }
            }
        })
    }

    const openModal = function (targetSelector) {
        const modal = document.querySelector(targetSelector)
        if (modal) {
            modal.classList.add('modal--active')
            disableBodyScroll(modal)
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(ModalBehaviour)
