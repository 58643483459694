import { Behaviour, behaviours } from '../../global/scripts/behaviours/behaviour'

const StoryLinkBehavior: Behaviour = {
    attach: (context: HTMLElement) => {
        context.querySelectorAll<HTMLAnchorElement>('.vol-story-link').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const target = e.currentTarget as HTMLAnchorElement;
                if (!target.dataset.name) {
                    return;
                }
                const parentWindow = window.parent ? window.parent : window;
                const url = new URL(parentWindow.location.href);
                url.searchParams.set('path', `/docs/${target.dataset.name}--documentatie`);
                parentWindow.location.href = url.toString();
            });
        });
    }
}

behaviours.addBehaviour(StoryLinkBehavior);

