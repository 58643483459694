import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const blockLogoSlider = (function () {
    const BASE_CLASS = 'blockLogoSlider'

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>(`.${BASE_CLASS}`)

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, {Navigation, default: Swiper}: typeof import('swiper')) {
        const sliderElement = block.querySelector<HTMLElement>(`.${BASE_CLASS}__slider`)
        const wrapperClass = `${BASE_CLASS}__items`
        const slideClass = `${BASE_CLASS}__item`

        const navClass = `${BASE_CLASS}__nav`
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--previous`)

        if (!sliderElement) {
            return false
        }

        new Swiper(sliderElement, {
            modules: [Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 200,
            slidesPerView: 'auto',
            spaceBetween: 20,
            slideActiveClass: `${BASE_CLASS}__item--active`,
            slideVisibleClass: `${BASE_CLASS}__item--visible`,
            watchSlidesProgress: true,
            loop: true,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 32,
                    slidesPerView: 4,
                },
            },
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockLogoSlider)
