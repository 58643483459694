import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import axios from 'axios'
import {debounce} from 'lodash'

type NamedInputs = {[key: string]: HTMLInputElement | null}

const AdminButtonPreview: Behaviour = {
    attach: (context: HTMLElement) => {
        if (context.classList.contains('preview-logic-attached')) {
            return
        }

        context.classList.add('preview-logic-attached')

        const getInputs = (context: HTMLElement) => {
            const inputs: NamedInputs = {
                'dark-normal-text': context.querySelector<HTMLInputElement>('.acf-field-button-dark-normal-text .acf-color-picker input'),
                'dark-normal-background': context.querySelector<HTMLInputElement>('.acf-field-button-dark-normal-background .acf-color-picker input'),
                'dark-normal-border': context.querySelector<HTMLInputElement>('.acf-field-button-dark-normal-border .acf-color-picker input'),
                'dark-hover-text': context.querySelector<HTMLInputElement>('.acf-field-button-dark-hover-text .acf-color-picker input'),
                'dark-hover-background': context.querySelector<HTMLInputElement>('.acf-field-button-dark-hover-background .acf-color-picker input'),
                'dark-hover-border': context.querySelector<HTMLInputElement>('.acf-field-button-dark-hover-border .acf-color-picker input'),
                'light-normal-text': context.querySelector<HTMLInputElement>('.acf-field-button-light-normal-text .acf-color-picker input'),
                'light-normal-background': context.querySelector<HTMLInputElement>('.acf-field-button-light-normal-background .acf-color-picker input'),
                'light-normal-border': context.querySelector<HTMLInputElement>('.acf-field-button-light-normal-border .acf-color-picker input'),
                'light-hover-text': context.querySelector<HTMLInputElement>('.acf-field-button-light-hover-text .acf-color-picker input'),
                'light-hover-background': context.querySelector<HTMLInputElement>('.acf-field-button-light-hover-background .acf-color-picker input'),
                'light-hover-border': context.querySelector<HTMLInputElement>('.acf-field-button-light-hover-border .acf-color-picker input'),
            }

            return inputs
        }

        const buildThemeConfig = (inputs: NamedInputs) => {
            return {
                id: new Date().valueOf(),
                dark: {
                    normal: {
                        text: inputs['dark-normal-text']?.value,
                        background: inputs['dark-normal-background']?.value,
                        border: inputs['dark-normal-border']?.value,
                    },
                    hover: {
                        text: inputs['dark-hover-text']?.value,
                        background: inputs['dark-hover-background']?.value,
                        border: inputs['dark-hover-border']?.value,
                    },
                },
                light: {
                    normal: {
                        text: inputs['light-normal-text']?.value,
                        background: inputs['light-normal-background']?.value,
                        border: inputs['light-normal-border']?.value,
                    },
                    hover: {
                        text: inputs['light-hover-text']?.value,
                        background: inputs['light-hover-background']?.value,
                        border: inputs['light-hover-border']?.value,
                    },
                },
            }
        }

        const updatePreview = (preview: HTMLDivElement, inputs: NamedInputs) => {
            const variant = preview.dataset.variant
            axios
                .post(
                    '/custom-button-preview',
                    {
                        props: {
                            variant: variant,
                            theme: buildThemeConfig(inputs),
                        },
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json',
                        },
                    }
                )
                .then((response) => {
                    preview.innerHTML = response.data
                })
        }

        const inputs = getInputs(context)

        const updatePreviews = () => {
            context.querySelectorAll<HTMLDivElement>('.button-preview').forEach((preview) => {
                updatePreview(preview, inputs)
            })
        }

        updatePreviews()

        Object.values(inputs).forEach((input) => {
            if (!input) {
                return
            }

            jQuery(input).on('change', debounce(updatePreviews, 250))
        })
    },
}

behaviours.addBehaviour(AdminButtonPreview)
