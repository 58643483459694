function docReady(fn: () => void) {
    const ready = function () {
        fn()

        // Add extra domReady event for storybook iframes
        if (window.location.href.indexOf('iframe.html') > -1) {
            document.addEventListener('DOMContentLoaded', fn)
        }
    }

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(ready, 1)
    } else {
        document.addEventListener('DOMContentLoaded', ready)
    }
}

export default docReady
