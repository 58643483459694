import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const TeaserSliderBehaviour = (function () {
    const attach = function (context) {
        const sliders = context.querySelectorAll('.teaserSlider')

        if (sliders.length === 0) {
            return
        }

        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            sliders.forEach((slider) => init(slider, context, module))
        })
    }

    const init = function (slider, context, module) {
        const block = slider.closest('.sliderParent')
        const Swiper = module.default
        const baseClass = 'teaserSlider'
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const navClass = 'slideNavigation__item'
        const buttonNext = block && block.querySelector(`.${navClass}--next`)
        const buttonPrev = block && block.querySelector(`.${navClass}--previous`)

        const swiper = new Swiper(slider, {
            modules: [module.Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            spaceBetween: 25,
            slidesPerView: 'auto',
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1400: {
                    spaceBetween: 16,
                    slidesPerView: 'auto',
                },
            },
        })

        swiper.on('breakpoint', (instance, params) => {
            if (params.slidesPerView === 'auto') {
                // Reset width set by swiper
                console.log('reset')
                block.querySelectorAll(`.${slideClass}`).forEach((slide) => (slide.style.width = 'auto'))
            }
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(TeaserSliderBehaviour)
