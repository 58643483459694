import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const BlockCodeBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll('code')

        if (blocks.length === 0) {
            return
        }

        if (!document.documentElement.querySelector('.storybook-backend')) {
            import(/* webpackChunkName: "slider" */ 'highlight.js').then((module) => {
                module.default.highlightAll()
            })
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockCodeBehaviour)
