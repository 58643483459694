import {behaviours} from '../behaviours/behaviour'

const NonGutenbergPagesBehaviour = (function () {
    const attach = function (context) {
        const hideElement = context.querySelector('.hideForNonGutenberg')
        if (hideElement && !document.body.classList.contains('block-editor-page')) {
            const titleElement = hideElement.querySelector('h2')
            const acfHeader = hideElement.closest('.acf-postbox')
            hideElement.style.display = 'none'
            if (titleElement) {
                acfHeader.querySelector('.postbox-header h2').innerText = titleElement.innerText
            }
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(NonGutenbergPagesBehaviour)
