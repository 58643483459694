import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const NavigationDrawerBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.navigationDrawer')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function (block) {
        block.querySelectorAll('.navigationDrawer__submenuTrigger').forEach((link) => {
            link.addEventListener('click', () => {
                link.parentElement.classList.toggle('navigationDrawer__item--active')
                block.querySelectorAll('.navigationDrawer__item--active').forEach((activeItem) => {
                    if (activeItem !== link.parentElement) {
                        activeItem.classList.remove('navigationDrawer__item--active')
                    }
                })
            })
        })

        const activeItem = block.querySelector('.navigationDrawer__link--active')
        const trigger = activeItem && activeItem.parentElement.querySelector('.navigationDrawer__submenuTrigger')
        if (trigger) {
            trigger.dispatchEvent(new Event('click'))
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(NavigationDrawerBehaviour)
