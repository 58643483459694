import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'

import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll'

const BlockMarqueeBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockMarquee__slider')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block))
    }

    const init = function (block: HTMLElement) {
        const slider = new Splide(block, {
            type: 'loop',
            height: '400px',
            gap: 15,
            autoWidth: true,
            pagination: false,
            arrows: false,
            clones: 12,
        })
        slider.mount({
            AutoScroll,
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockMarqueeBehaviour)
