import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const DrawerBehaviour = (function () {
    const baseClass = 'drawer'

    let drawers: NodeListOf<HTMLElement> | null = null

    const attach = function (context: HTMLElement) {
        drawers = context.querySelectorAll<HTMLElement>(`.${baseClass}`)
        const toggleButtons = context.querySelectorAll<HTMLElement>('.drawerTrigger')

        // No menu found, so exit
        if (drawers.length === 0) {
            return false
        }

        // Behaviour already attached, so exit
        if (drawers[0].classList.contains(`${baseClass}--behaviourAttached`)) {
            return false
        }

        drawers.forEach((drawer) => drawer.classList.add(`${baseClass}--behaviourAttached`))

        toggleButtons.forEach((button) => init(button))

        const goToParentMenuTrigger = document.querySelector('.drawer__gotoToParentMenu')
        goToParentMenuTrigger && initGoToParentMenu(goToParentMenuTrigger)

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                closeAll()
            }
        })
    }

    const getPreferredDrawers = function (): HTMLElement[] | null {
        const primaryDrawer = document.querySelector<HTMLElement>('.drawer--primary')
        const secondaryDrawer = document.querySelector<HTMLElement>('.drawer--secondary')

        if (!primaryDrawer) {
            return null
        }

        if (!secondaryDrawer) {
            return [primaryDrawer]
        }

        return [secondaryDrawer, primaryDrawer]
    }

    const open = function () {
        const drawers = getPreferredDrawers()

        if (!drawers) {
            return false
        }

        document.documentElement.classList.add('drawerIsOpen')

        drawers.forEach((drawer) => {
            const menu = drawer.querySelector('.drawer__scroll')
            drawer.classList.add(`${baseClass}--open`)
            menu && disableBodyScroll(menu)
        })
    }

    const close = function (drawer: HTMLElement) {
        const menu = drawer.querySelector('.drawer__scroll')
        drawer.classList.remove(`${baseClass}--open`)
        menu && disableBodyScroll(menu)

        if (!document.querySelector('.drawer--open')) {
            document.documentElement.classList.remove('drawerIsOpen')
        }
    }

    const closeAll = function () {
        if (!drawers) {
            return false
        }

        drawers.forEach((drawer) => {
            drawer.classList.remove(`${baseClass}--open`)
            const menu = drawer.querySelector('.drawer__scroll')
            if (menu) {
                enableBodyScroll(menu)
            }
        })
        document.documentElement.classList.remove('drawerIsOpen')
    }

    const init = function (toggleButton: HTMLElement) {
        const drawer = toggleButton.closest<HTMLElement>('.drawer')
        toggleButton.addEventListener('click', function () {
            const isOpen = drawer && drawer.classList.contains('drawer--open')
            if (isOpen && drawer) {
                closeAll()
            } else {
                open()
            }
        })
    }

    const initGoToParentMenu = function (goToParentMenuTrigger: Element) {
        goToParentMenuTrigger.addEventListener('click', function () {
            const drawer = goToParentMenuTrigger.closest<HTMLElement>('.drawer')
            drawer && drawer.classList.add('drawer--switchAnimation')
            drawer?.addEventListener('transitionend', function () {
                drawer.classList.remove('drawer--switchAnimation')
            })
            drawer && close(drawer)
        })
    }

    return {
        attach: attach,
        close: close,
        open: open,
    }
})()

behaviours.addBehaviour(DrawerBehaviour)
