export interface Behaviour {
    attach: (context: HTMLElement) => void
}

export interface BehavioursInterface {
    addBehaviour: (behaviour: Behaviour) => void
    attachBehaviours: (context: HTMLElement) => void
}

export const Behaviours = (): BehavioursInterface => {
    const behaviours: Behaviour[] = []

    let attachedTo: HTMLElement | null = null

    const addBehaviour: (behaviour: Behaviour) => void = (behaviour) => {
        behaviours.push(behaviour)
        if (attachedTo) {
            behaviour.attach(attachedTo)
        }
    }

    const attachBehaviours: (context: HTMLElement) => void = (context) => {
        behaviours.forEach((behaviour) => {
            if (typeof behaviour.attach === 'function') {
                behaviour.attach(context)
            }
            attachedTo = context
        })
    }

    return {
        addBehaviour,
        attachBehaviours,
    }
}

export const behaviours = Behaviours()
