import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const BlockHeroFilterBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockHeroFilter')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context))
    }

    const init = function (block) {
        const trigger = block.querySelector('.blockHeroFilter__trigger')
        const filter = block.querySelector('.blockHeroFilter__filter')
        if (trigger && filter) {
            trigger.addEventListener('click', () => {
                filter.classList.toggle('blockHeroFilter--open')
            })
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockHeroFilterBehaviour)
