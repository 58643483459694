import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import {debounce} from 'lodash'

const BlockTextSlider = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.blockTextSlider')

        if (blocks.length === 0) {
            return
        }
        // Async load swiper library and init slideshow when loaded
        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, context, module))
        })
    }

    const init = function (block, context, module) {
        const Swiper = module.default
        const baseClass = 'blockTextSlider'
        const slider = block.querySelector(`.${baseClass}__slider`)
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const navClass = 'slideNavigation__item'
        const buttonNext = block && block.querySelector(`.${navClass}--next`)
        const buttonPrev = block && block.querySelector(`.${navClass}--previous`)
        const columns = block.getAttribute('data-columns') ?? 4

        let sliderPerViewDesktop = 4
        let spaceBetweenDesktop = 15

        if (columns === '2') {
            sliderPerViewDesktop = 2.05
            spaceBetweenDesktop = 20
        }

        new Swiper(slider, {
            modules: [module.Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            spaceBetween: 10,
            slidesPerView: 'auto',
            slideActiveClass: `${baseClass}__item--active`,
            threshold: 5,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                },
                1400: {
                    slidesPerView: sliderPerViewDesktop,
                    spaceBetween: spaceBetweenDesktop,
                },
            },
        })

        const checkNavigation = function () {
            const navigation = block.querySelector('.blockTextSlider__navigation')
            if (navigation) {
                navigation.classList.toggle('blockTextSlider__navigation--disabled', block.querySelectorAll('.slideNavigation__item--disabled').length === 2)
            }
        }

        window.addEventListener('resize', debounce(checkNavigation, 250))
        checkNavigation()
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockTextSlider)
