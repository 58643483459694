import Cookies from 'js-cookie'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const BlockGlobalMessageBehaviour = (function () {
    const attach = function (context) {
        const baseClass = 'blockGlobalMessage'
        const blocks = context.querySelectorAll(`.${baseClass}`)

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block, context, baseClass))
    }

    const init = function (block, context, baseClass) {
        const closeButton = block.querySelector(`.${baseClass}__closeButton`)
        const messageId = block.getAttribute('data-message-id')

        closeButton &&
            closeButton.addEventListener('click', () => {
                block.classList.add(`${baseClass}--closing`)
                Cookies.set('notification-hidden', messageId)
            })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockGlobalMessageBehaviour)
