import {behaviours} from '../behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

        const getAttributeForBlock = function (block, attribute) {
            if (!block) {
                return null
            }
            if (!block.hasAttribute(attribute)) {
                return null
            }
            return block.getAttribute(attribute)
        }

        const getBackgroundForBlock = function (block) {
            const background = getAttributeForBlock(block, 'data-background')
            return background && background !== 'none' ? background : false
        }

        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const background = block.getAttribute('data-background')
                const previousBlock = block.previousElementSibling

                const blockPullBackground = getAttributeForBlock(block, 'data-pull-background')
                const nextBlockBackground = getBackgroundForBlock(block.nextElementSibling)
                const previousBlockBackground = getBackgroundForBlock(block.previousElementSibling)

                if ((blockPullBackground === 'bottom' || blockPullBackground === 'both') && nextBlockBackground) {
                    block.classList.add(`${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlockBackground)}`)
                }

                if ((blockPullBackground === 'top' || blockPullBackground === 'both') && previousBlockBackground) {
                    block.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlockBackground)}`)
                }

                if (!previousBlockBackground) {
                    return
                }

                if (previousBlock.getAttribute('data-background') === background && previousBlock.classList.contains(block.classList[0])) {
                    previousBlock.classList.add(`${previousBlock.classList[0]}--nextBlockSameBackgroundSameType`)
                    previousBlock.setAttribute('data-next-block', block.classList[0])
                } else if (previousBlock.getAttribute('data-background') === background) {
                    previousBlock.classList.add(`${previousBlock.classList[0]}--nextBlockSameBackground`)
                    previousBlock.setAttribute('data-next-block', block.classList[0])
                }
            })
        }

        return {
            attach: attach,
        }
    })()
)
